.dashboard-view {
    background-color: rgba(211,211,211, 0.2);
    flex-grow: 1;
}

.card-offset-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #DEBD3A;
    position: absolute;
    align-self: center;
    top: -30px;
    display: flex;
    border: 2px solid #FFDE5C;
}

.card {
    background-color: #DEBD3A !important;
}

.card-body {
    margin-top: 25px !important;
    text-align: center;
}

.card-text {
    font-size: 48px;
    font-weight: 600;
}