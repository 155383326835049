.App {
  padding: 3% 8%;
  background-color: #16A085;
  background-image: linear-gradient(132deg, #16A085 0%, #F4D03F 100%);
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.btn-twitch { 
  color: #FFFFFF !important; 
  background-color: #6441A5 !important; 
  border-color: #2F1F4E !important; 
} 
.btn-twitch:hover, 
.btn-twitch:focus, 
.btn-twitch:active, 
.btn-twitch.active, 
.open .dropdown-toggle.btn-twitch { 
  color: #FFFFFF; 
  background-color: #472e75; 
  border-color: #2F1F4E; 
} 
 
.btn-twitch:active, 
.btn-twitch.active, 
.open .dropdown-toggle.btn-twitch { 
  background-image: none; 
} 
 
.btn-twitch.disabled, 
.btn-twitch[disabled], 
fieldset[disabled] .btn-twitch, 
.btn-twitch.disabled:hover, 
.btn-twitch[disabled]:hover, 
fieldset[disabled] .btn-twitch:hover, 
.btn-twitch.disabled:focus, 
.btn-twitch[disabled]:focus, 
fieldset[disabled] .btn-twitch:focus, 
.btn-twitch.disabled:active, 
.btn-twitch[disabled]:active, 
fieldset[disabled] .btn-twitch:active, 
.btn-twitch.disabled.active, 
.btn-twitch[disabled].active, 
fieldset[disabled] .btn-twitch.active { 
  background-color: #6441A5; 
  border-color: #2F1F4E; 
} 
 
.btn-twitch .badge { 
  color: #6441A5; 
  background-color: #FFFFFF; 
}