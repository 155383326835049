.home-info-card {
    display: flex;
    flex-direction: column;
}

.sales-title-container {
    align-items: flex-end;
}

.sales-title {
    font-family: 'Courier New', monospace;
    font-size: 50px;
    font-weight: 600;
    width: 100% !important;
    align-content: end;
}

.sales-subtitle {
    font-family: 'Courier New', monospace;
    font-size: 26px;
    font-weight: 400;
    width: 100% !important;
}

.action-button-container {
    display: flex;
    flex-direction: row;
}

.action-button-container .btn {
    width: 80%;
    height: 56px;
}